import consumer from "./consumer"



$(document).on('turbolinks:load', function () {
    var token = $('#token').html();
   
  consumer.subscriptions.create({channel: "GameChannel", token: token}, {
    
    connected() {
      console.log("Connected to the game!");
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      console.log("Recieving:");
      console.log(data.spin);
      selectNo(data.spin);
      if(data.bias) notify();
    }
  });
  
});
